<template>
  <div class="mt-1 mx-3">
    <insurer></insurer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Insurer from "@/components/accounting/accounting-insurer/index.vue";

export default {
  props: {},
  components: { Insurer },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Contabilità Compagnia",
      path: "accounting-insurer",
      level: 2,
    });
  },
};
</script>
